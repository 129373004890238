.navigationLinks{
    color: rgb(255, 255, 255);
}

.navigationText{
    color: black;
}

.navigationImage{
    width: 50%;
}

.headerLogoLeft{
    height: 100%;
    width: 100%;
    cursor:pointer;
}

.navigationImage{
    height: 50px;
    width: 50%;
}

.Header{
    position: fixed;
    top:0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: nowrap;
    height: 50px;
    background-color:rgb(51, 51, 51);
    width: 100%;
    z-index: 100;
}


.HeaderMobile{
    padding-top: 10%;
    position: fixed;
    top:0;
    width: 95%;
    z-index: 100;
    display: flex;
    justify-content: flex-end;
    padding: 3%;
}

.HeaderMobile-svg-button {
    height: 100%;
    width: 100%;
    cursor:pointer;
}

.HeaderMobile-dropdown-button{
    position: fixed;
    top: center;
    display:block;
    height: 50px;
    margin: auto;
    width: 90%;
}

.polygon-svg-spinner{
    display: absolute;
    height: 35px;
    transform: translate(-100px, -500px);
    animation: rotateSvg 30s linear infinite;
    z-index: -100;
    animation-timing-function: ease-in-out;
    width: 40px;
    height: 50px;
    fill: #fff;
    stroke: #f2f2f2;
    stroke-miterlimit: 10;
    stroke-width: 8px;
    /* animation-delay: 0s; */
}
 @keyframes rotateSvg{
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    } 
  }

  .mobileGoToTop{
    position: fixed;
    display: absolute;
    bottom: 70px;
    right: 20px;
    z-index: 100;

  }