.rootPolygonOverlay{
    background-image:url(../Images/PolygonOverlay.svg);
    background-repeat: no-repeat;
    background-size: 230%;
    background-position: bottom;
    width: 100%;
    height: 300px;
    margin: 0;
    padding: 0%;
    z-index: 3;
    transform:translateY(10px)
}

.rootPolygonOverlayInverted{
background-image:url(../Images/PolygonOverlay.svg);
background-repeat: no-repeat;
background-size: 230%;
background-position: bottom;
width: 100%;
height: 300px;
margin: 0;
padding: 0%;
z-index: 3;
transform:translateY(-10px)rotate(180deg);
}