.Main-side-bar{
    /* display:block; */
    position:fixed;
    display: absolute;
    left: 0;
    top: 10%;
    margin-top: 5%;
    background-color: rgb(37, 37, 37);
    width: 3%;
    min-width: 50px;
    height: 50%;
    border-bottom-right-radius: 15px;
    border-top-right-radius: 15px;
    z-index: 10;
    box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.719);
}

  .linkedin {
    height: 50px;
    margin: 10%;
  }

  .Settings-Open{
    display: absolute;
    position: fixed;
    margin-top: 5%;
    width: 300px;
    height: 500px;
    left: 4%;
    top: 10%;
    background-color: rgb(37, 37, 37);
    box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.719);
    z-index: 20;
  }

  .Settings-container{
    display: absolute;
    position: fixed;
    width: 100%;
    height: 100%;
    top:0;
    left: 80px;
    z-index: 10;
    /* background-color: aliceblue; */
  }
  
  .About-Open{
    display: absolute;
    position: fixed;
    margin-top: 5%;
    width: 300px;
    height: 500px;
    left:4%;
    top: 10%;
    background-color: rgb(37, 37, 37);
    box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.719);
    z-index: 100;
  }

  .mouseOverHandler {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 50px;
  }

  .about-container{
    border-bottom: 2px;
    border-top: 0px;
    border-left: 0px;
    border-right: 0px;
    border-color: black;
    border-style: solid;
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-bottom: 10px;
    z-index: 100;
  }
