.SectionOneLeft{
    background-color: white;
}

.SectionOne-cardsMain{
    background-color: white;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    z-index: 1;
    padding-bottom: 5%;
    height: 100%;
    width: 100%;
    overflow: scroll;
}

.SectionOne-Card-Mobile{
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    align-items: center;
    margin: 3%;
    width: 100%;
    min-width: 350px;
    height: 600px;
    background-color: rgb(54, 54, 54);
    box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.719);
    border-radius: 15px;
    padding: 15% 3% 10% 3% ;
    animation: cardEntering 1s;
}

.SectionOne-Card-1{
    top:0;
    display: flex;
    position:relative;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 3%;
    width: 80%;
    height: 600px;
    background-color: rgb(54, 54, 54);
    box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.719);
    background-size: 500px 500px;
    background-position: center;
    border-radius: 15px;
    padding: 3%;
    animation: cardEntering 1s;
}

.SectionOne-Card-2{
    top:0;
    display: flex;
    position:relative;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 3%;
    width: 80%;
    height: 650px;
    background-color: rgb(54, 54, 54);
    box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.719);
    background-size: 500px 500px;
    background-position: center;
    border-radius: 15px;
    padding: 3%;
    animation: cardEntering 1s;
}

.SectionOne-Card-3{
    display: flex;
    position:relative;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 3%;
    width: 80%;
    height: 700px;
    background-color: rgb(54, 54, 54);
    box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.719);
    background-size: 500px 500px;
    background-position: center;
    border-radius: 15px;
    padding: 3%;
    animation: cardEntering 1s;
}


@keyframes cardEntering {
    from {
        transform:translateX(500px);
        opacity: 0%;
    }
    to {
        transform:translateX(0px);
        opactity: 100%;
    }
}

.SectionOne-Card-Leaving{
    display: flex;
    position:relative;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 3%;
    width: 400px;
    min-width: 400px;
    height: 600px;
    max-height: max-content;
    background-color: rgb(54, 54, 54);
    box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.719);
    background-size: 500px 500px;
    background-position: center;
    border-radius: 15px;
    padding: 3%;
    animation: cardLeaving 1s;
}

@keyframes cardLeaving {
    from {
        transform:translateX(0px);
        opacity: 100%;
    }
    to {
        transform:translateX(-500px);
        opactity: 0%;
    }
}

.redfoxinu-img{
    width: 70%;
    height: 350px;
    display: block;
    z-index: 100;
    border-radius: 10px;
}

.redfoxinu-img-mobile{
    width: 70%;
    height: 30vh;
    display: block;
    z-index: 100;
    border-radius: 10px;
}

.Live-Preview-button{
    width: 60%;
}

.SectionOne-Card-Summary{
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin: 3%;
    width: 40%;
    height: 100px;
    background-color: rgb(54, 54, 54);
    box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.719);
    background-size: 500px 500px;
    background-position: center;
    border-radius: 15px;
    padding: 3%;
}
.SectionOne-Card-Svg{
    width: 40%;
    height: 40%;
    text-align: center;
}