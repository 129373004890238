html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  z-index: -3;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

h4{
  text-align:center;
  font-weight:500;
}
#root{
  /* height: 4000px; */
  margin: 0%;
  display: flex;
  justify-content: top;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  max-width: 100vw;
  background:linear-gradient(180deg, rgba(6, 3, 7, 0.932),rgba(6, 3, 7, 0.593), rgba(6, 3, 7, 0.63), rgba(0, 0, 0, 0.744));
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-color: rgb(152, 182, 145);
  z-index: -10;
  overflow: hidden;
}
