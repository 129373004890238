.Contact-Form-Main{
    position: relative;
    height: 500px;
    width: 50%;
    padding-top: 10%;
    padding-bottom: 10%;
}

.Contact-Form-Overlay{
    position: relative;
    width: 100%;
    height: 90px;
    transform: rotate(2deg);
    box-shadow: 50px 1px 100px rgb(0, 0, 0);
}

.overlayContainer{
    position: relative;
    transform: translateY();
    width: 100%;
    height: 50px;
}

.Form-Container{
    width: 100%;
    max-width: 500px;
}

.linkedin-link{
    cursor: pointer;
}


.contact-form-right{
    z-index: 4;
    border-left: 3px solid black;
    margin-bottom: 3rem;
}

@media only screen and (max-width: 600px) {
    .contact-form-right{
       display: none;
    }
    .Contact-Form-Main{
        position: relative;
        height: 500px;
        width: 100%;
        padding-top: 10%;
    }
  }