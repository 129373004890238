.Solutions-Main{
    margin-bottom: 10%;
    width: 100%;
}

.Solutions-code-container{
    min-width: 400px;
    background-color: rgb(223, 223, 223);
    border-radius: 10px;
    box-shadow: inset 0px 0px 10px 0px black;
}