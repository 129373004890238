.MainFlex {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 150px;
    background-size: cover;
    background-position: center;
    width: 90%;
    border-radius: 15px;
    z-index: 1;
    min-width: 300px;
    min-height: 400px;
    flex-wrap:wrap;
}
/*MainFlex flex-column d-flex flex-row justify-content-center align-items-center text-light*/

.MainFlexMobile{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    margin-top: 80px;
    background-size: cover;
    background-position: center;
    width: 90%;
    border-radius: 15px;
    z-index: 1;
    min-width: 200px;
    height: 400px;
    flex-wrap:wrap;
}

.Main-welcomeContainer{
    min-width: 200px;
    width: 40%;
    min-width: 300px;
}

.Main-welcomeContainerMobile{
    min-width: 200px;
    width: 40%;
    min-width: 300px;
}

.main-svgLink{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content:center;
    height: 100%;
    width: 50%;
}

.main-svgSpan{
    height: 100%;
    width: 50%;
}

.main-Canvas{
    width: 100%;
    height: 80%;
    position:absolute;
    position: top;
    z-index: 0;
    transform:translate(-44px,-0px);
    animation: translateCanvas 10s linear infinite;
    -o-animation: translateCanvas 10s linear infinite;
}


@keyframes translateCanvas {
    from{
        transform:translate(-0px,850px);
    }
    to{
        transform:translate(-0px,-650px);
    }
}

.Links-Container-Mobile{
    z-index: 100;
    width: 80%;
}

.Links-Container{
    z-index: 100;
    width: 10%;
}

.OnHoverShowCode{
    display: absolute;
    left: 10px;
    top: 0%;
    z-index: 10;
    position:absolute;
    transform: translateY( 100px);
    top: 0px;
}

.ShowCode {
    display: absolute;
    position: absolute;
    padding: 10px;
    top: 30px;
    left: 100px;
    background-color: #f2f2f2;
    width: 600px;
    z-index: 100;
    box-shadow: 0px 0px 10px 3px rgba(0, 0, 0, 0.719);
}


.MainLinks{
    z-index: 100;
}

.Main-Links-Container{
    width: 50%;
    height: 70px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
}

.Main-Links{
    width: 80px;
}

.polygon-svg{
    z-index: 12;
    width: 40px;
    height: 50px;
    fill: #fff;
    stroke: #f2f2f2;
    stroke-miterlimit: 10;
    stroke-width: 8px;
}

h1{
    font-size: 6vh;
    font-family: monospace;
    font-weight: 600;
}

p{
    font-size: 2vh;
    font-weight: 500;
    text-align: center;
}

h2 {
    font-size: 4vh;
    font-family: monospace;
    font-weight: 700;
    text-align: center;
}

h3 {
    font-size: 3vh;
    font-family: monospace;
    font-weight: 700;
    text-align: center;
}

.text-accent{
    color: rgb(0, 255, 128);
}

.bg-accent{
    background-color: rgb(0, 255, 128);
}

.bg-outline-accent{

    border-radius: 5px;
    border-style: solid;
    border-width: 2px;
    border-color: rgb(0, 255, 128);
}

.text-border-outline{
    border-style: solid;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-width: 3px;
    border-color:
    rgb(0, 255, 128);
}

.animated-flex {
    animation: entryAnimation 1s;
}


@keyframes entryAnimation{
    from {
        transform:translateY(500px);
        opacity: 0%;
    }
    to {
        transform:translateY(0px);
        opactity: 100%;
    }
}

button:hover{
    background-color: rgb(243, 255, 231);
    border-color: rgb(243, 255, 231);
    color: rgb(255, 255, 235);
}